var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CollaborateurMissions"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"color":"black"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CToaster',{attrs:{"autohide":5000}},[_c('CToast',{staticClass:"danger-toast",attrs:{"show":_vm.isTooMuchMissions},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('strong',{staticClass:"text-danger"},[_vm._v("Vous avez "+_vm._s(_vm.missions.length)+" missions en cours !")])]},proxy:true},{key:"default",fn:function(){return [_c('span',[_vm._v("N'oubliez pas que vous pouvez "),_c('strong',[_vm._v("archiver")]),_vm._v(" les missions qui sont terminées.")]),_c('br'),_c('small',[_vm._v("Aucune donnée ne sera perdue, et vous pourrez à tout moment réactiver votre mission")])]},proxy:true}])})],1),_c('CRow',[_c('CCol',[_c('h1',[_vm._v(" Vos missions en cours ")])])],1),_c('CCard',{staticClass:"mt-3"},[_c('CCardHeader',[_c('strong',[_vm._v("Accédez aux missions en cours sur lesquelles vous êtes affecté")])]),_c('CCardBody',[(_vm.userGroupContains(['MANAGER']) && _vm.missionsRender.length > 0)?_c('CRow',[_c('CCol',{staticClass:"text-right"},[_c('exportTableDataComponent',{attrs:{"data":_vm.missionsRender,"fields":_vm.missionFileRenderFields,"name":"Eclerk - Vos missions","label":"Exporter la liste"}})],1)],1):_vm._e(),_c('CDataTable',{staticClass:"cursor-table",attrs:{"hover":"","items":_vm.missionsRender,"fields":_vm.missionsRenderFields,"noItemsView":{ noResults: "Aucun résultat", noItems: "Aucun résultat" }},on:{"row-clicked":_vm.goToMissionPage},scopedSlots:_vm._u([{key:"client_is_firm",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.client_is_firm === true)?_c('CIcon',{attrs:{"name":"cil-building"}}):_c('CIcon',{attrs:{"name":"cil-user"}})],1)]}},{key:"nom",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.nom))]),(item.is_paiement_alert)?_c('div',[_c('em',{staticClass:"text-danger"},[_vm._v("Alerte de paiement levée")])]):_vm._e()])]}},{key:"types",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.types),function(type){return _c('em',{key:type.id},[_vm._v(_vm._s(type.name)+" ")])}),0)]}},{key:"collaborateurs",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.collaborateurs),function(collaborateur){return _c('em',{key:collaborateur.id},[_vm._v(_vm._s(collaborateur.full_name)+", ")])}),0)]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('small',[_vm._v(" "+_vm._s(item.description))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }