<template>
  <div class="exportTableDataComponent">

    <CModal
      :show.sync="isExtractingFile"
      :no-close-on-backdrop="true"
      :title="label"
      size="lg"
      color="dark"
      class="text-left"
    >
      <p class="text-dark">
        Exportez votre fichier au format Excel ou CSV selon les modalités de vos outils de bureautique.
      </p>

      <CSelect
        :value.sync="formatChosen"
        label="Format de fichier"
        :options="formatSelectOptions"
        custom
      />

      <CButton
        v-if="formatChosen=='XLS'"
        block
        color="outline-primary">
        <download-excel
            :data="data"
            :fields="fields"
            :name="name"
            :before-finish='closeExportModal'
            worksheet="Vos données"
            >
            Exporter vos données
        </download-excel>
      </CButton>

      <CButton
        v-else
        block
        color="outline-primary">
        <download-excel
          :data="data"
          :fields="fields"
          type="csv"
          :before-finish='closeExportModal'
          :name="name">
            Exporter vos données
        </download-excel>
      </CButton>


      <template #footer>
        <CButton @click="isExtractingFile = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <CButton
      size="sm"
      color="primary"
      @click="isExtractingFile = true">
      <CIcon name="cil-cloud-download"/> {{ label }}
    </CButton>


  </div>
</template>

<script>
export default {
  name: 'exportTableDataComponent',
  props: {
    data: Array,
    fields: Object,
    name: String,
    label: String
  },
  data () {
    return  {
      isExtractingFile: false,

      formatChosen: 'XLS',
      formatSelectOptions: [
        { label: 'Excel (.xls)', value: 'XLS' },
        { label: "Format CSV (.csv)", value: 'CSV' },
      ],
    }
  },
  methods: {
    closeExportModal () {
      this.isExtractingFile = false
    }
  }
}
</script>
