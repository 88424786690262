nom_du_client<template>
  <div class="CollaborateurMissions">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CToaster :autohide="5000">
      <CToast
        class="danger-toast"
        :show="isTooMuchMissions"
      >
      <template #header>
        <strong class="text-danger">Vous avez {{missions.length}} missions en cours !</strong>
      </template>
      <template #default>
        <span>N'oubliez pas que vous pouvez <strong>archiver</strong> les missions qui sont terminées.</span><br>
        <small>Aucune donnée ne sera perdue, et vous pourrez à tout moment réactiver votre mission</small>

      </template>
      </CToast>
    </CToaster>


    <CRow>
      <CCol>
        <h1> Vos missions en cours </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardHeader>
        <strong>Accédez aux missions en cours sur lesquelles vous êtes affecté</strong>
      </CCardHeader>
      <CCardBody>
        <CRow v-if="userGroupContains(['MANAGER']) && missionsRender.length > 0">
          <CCol class="text-right">
            <exportTableDataComponent
              v-bind:data="missionsRender"
              v-bind:fields="missionFileRenderFields"
              name="Eclerk - Vos missions"
              label="Exporter la liste"
            />

          </CCol>
        </CRow>
        <CDataTable
          class="cursor-table"
          hover
          :items="missionsRender"
          :fields="missionsRenderFields"
          @row-clicked="goToMissionPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #client_is_firm="{item}">
          <td>
            <CIcon v-if="item.client_is_firm === true" name="cil-building"/>
            <CIcon v-else name="cil-user"/>
          </td>
        </template>
        <template #nom="{item}">
          <td>
           <span>{{item.nom}}</span>
           <div v-if="item.is_paiement_alert">
             <em class="text-danger">Alerte de paiement levée</em>
           </div>
          </td>
        </template>

        <template #types="{item}">
          <td>
            <em v-for="type in item.types" v-bind:key="type.id">{{type.name}} </em>
          </td>
        </template>

        <template #collaborateurs="{item}">
          <td>
            <em v-for="collaborateur in item.collaborateurs" v-bind:key="collaborateur.id">{{collaborateur.full_name}}, </em>
          </td>
        </template>

        <template #description="{item}">
          <td>
            <small> {{item.description}}</small>
          </td>
        </template>

        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import userMixins from '@/mixins/userMixins'
import { APIUserConnected } from '@/api/APIUserConnected'

import exportTableDataComponent from '@/components/exportTableDataComponent'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'CollaborateurMissions',
  mixins: [userMixins],
  components: {
    Loading,
    exportTableDataComponent
  },
  data: function () {
    return {
      // ------------- MISSION -----------
      missions: [],
      missionsRender: [],
      missionsRenderFields: [
        { key: "client_is_firm", label: "", tdClass: 'ui-helper-center'},
        { key: "nom_du_client", label: "Nom du client", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "nom", label: "Intitulé mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "types", label: "Type de mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "referent", label: "Référent mission", tdClass: 'ui-helper-center', _style: "min-width: 150px;"},
        { key: "collaborateurs", label: "Collaborateurs affectés", tdClass: 'ui-helper-center', _style: "min-width: 200px;" },
        { key: "description", label: "Description", tdClass: 'ui-helper-center', _style: "min-width: 200px;"},
      ],

      missionFileRenderFields: {
        "Client": 'nom_du_client',
        'Mission': 'nom',
        'Référent': 'referent',
        'Description':'description'
      },

      isAllCollaborateurMissionsLoading: false,

      isTooMuchMissions: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllCollaborateurMissionsLoading) {
        return true
      }
      return false
    }
  },
  created: function() {
    this.connectedCollaborateurAllMissions()
  },
  watch: {
    missions: function (newMissions) {
      if (newMissions.length == 0) {
        this.missionsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newMissions.length; i++) {
          final_array.push(
            {
              'id': newMissions[i].id,
              'client_is_firm': newMissions[i].client_is_firm,
              'nom_du_client': newMissions[i].client_name,
              'nom': newMissions[i].name,
              'types': newMissions[i].types,
              'referent': newMissions[i].referent_full_name,
              'collaborateurs': newMissions[i].collaborateurs,
              'description': newMissions[i].description,
              'is_paiement_alert': newMissions[i].is_paiement_alert

            }
          )
        }
        this.missionsRender = final_array
      }
      if (this.missions.length > 30) {
        this.isTooMuchMissions = true
      }
    },
  },
  methods: {
    connectedCollaborateurAllMissions () {
      this.isAllCollaborateurMissionsLoading = true
      apiUserConnected.connectedCollaborateurAllMissions(this.token)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllCollaborateurMissionsLoading = false
      })
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToMissionPage(item) {
      this.$router.push('/mission/' + item.id)
    }
  }
}
</script>

<style>
.toast {
  flex-basis: auto !important;
}
.danger-toast {
  background-color: #F8E0E0;
}
</style>
